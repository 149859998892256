import React from "react";
import { Table, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import moment from "moment";

class ContentTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(props) {
        // Generates table data when the component mounts
        this.tableData = this.generateTableData(props.questions, props.t);
    }

    splitArrayByLengths(ansArr, arraySplitLength) {
        const result = [];
        let currentIndex = 0;

        for (const length of arraySplitLength) {
            // Extract a sub-array of the specified length
            const subArray = ansArr.slice(currentIndex, currentIndex + length);
            result.push(subArray);
            // Move the currentIndex forward by the length
            currentIndex += length;
        }

        return result;
    }

    generateTableData(questions, t) {
        // Return an empty array if `questions.key` is not defined or valid
        if (!questions || !questions.key) return [];

        // Map over the questions and generate row data
        return questions.key.map((k, i) => {
            if (k === "epi1_cancer_disease_list")
                return null;

            const rowData = {
                questionText: questions.text[i],
                answers: []
            };

            let answerLength = [], cancerFlag = false;
            if (questions.answers[i] && questions.answers[i].length > 0) {
                if (questions.answers[i][0].question === "epi1_cancer_family_list") {
                    cancerFlag = true;
                    questions.answers[i].map((ans, idx) => {
                        answerLength[idx] = ans.answer.length;
                    })
                }
                if (cancerFlag) {
                    const idx = questions.answers.findIndex(e => e[0].question === "epi1_cancer_disease_list");
                    // Include the parent key of family member inside the cancer disease list answer array
                    questions.questionAnswer[idx].forEach((element, num) => {
                        const ansKey = questions.answers[idx][num].parent_answer;
                        for (let i = 0; i < element.length; i++) {
                            element[i] = { ...element[i], parent_answer: ansKey };
                        }
                    });

                    // Split the cancer disease answer array into groups based on the cancer family member answer array
                    const splitAnswerArray = this.splitArrayByLengths(questions.questionAnswer[idx], answerLength);

                    rowData.answers = questions.questionAnswer[i].map((ansGroup, index) => {
                        let wholeAnswer = ""
                        for (let j = 0; j < ansGroup.length; j++) {
                            wholeAnswer += ansGroup[j].text + '-';
                            // Map the cancer person and cancer disease
                            const ansMap = splitAnswerArray[index].flatMap(innerArray =>
                                innerArray.filter(item => item.parent_answer === ansGroup[j].value)
                            );
                            // console.log(ansGroup[j].text, "-----------------", ansMap, "ansMap")
                            wholeAnswer += ansMap.map(e => e.text).join(",");
                            wholeAnswer += " | ";
                            // console.log(wholeAnswer, "wholeAnswer in map")
                        }
                        // remove the last "| " from the string
                        return wholeAnswer.slice(0, -2);
                    })
                } else {
                    rowData.answers = questions.questionAnswer[i].map(ansGroup =>
                        ansGroup.map(e => e.text).join(",")
                    );
                }
            } else {
                rowData.answers = [t('no_answers')]; // Fallback if no answers exist
            }

            return rowData;
        });
    }

    render() {
        const { questions, loader, message, t } = this.props;
        const answersAvailable = questions && questions.answers && questions.answers.length > 0;

        return (
            <div className="cmnTable">
                <Table responsive striped>
                    <thead>
                        <tr>
                            {questions && questions.text && questions.text.length > 0 && (
                                <th>{t('questions')}</th>
                            )}
                            {answersAvailable && questions.answers[0].map((ans, i) => (
                                <th key={i}>
                                    {i === 0 ? t('current_answers') : t('previous_answers')}<br />
                                    {ans.createdAt && (
                                        <small>({moment(ans.createdAt).format("DD-MM-YYYY")})</small>
                                    )}
                                </th>
                            ))}
                            {answersAvailable && questions.answers[0].length === 0 && (
                                <th>{t('current_answers')}<small></small></th>
                            )}
                        </tr>
                    </thead>
                    {!loader && answersAvailable && (
                        <tbody>
                            {this.tableData.map((row, rowIndex) => (row &&
                                <tr key={rowIndex}>
                                    <td>{row.questionText}</td>
                                    {row.answers.map((answer, answerIndex) => (
                                        <td key={answerIndex}>{answer}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    )}
                </Table>
                {(!questions || questions.length === 0) && !loader && (
                    <h5 style={{ textAlign: 'center', paddingTop: '20px' }}>{message}</h5>
                )}
                {loader && (
                    <Spinner
                        className="spinnerClass"
                        animation="border"
                        variant="info"
                        style={{ marginLeft: '50%', padding: '20px', marginTop: '30px', marginBottom: '30px' }}
                    />
                )}
            </div>
        );
    }
}

export default withTranslation()(ContentTable);
