import { React } from "react";
import { Dropdown } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { withTranslation } from "react-i18next";

const FilerButton = (props) => {
    const { t } = props;

    return (
        <>
            <Dropdown className="customDropdown">
                <Dropdown.Toggle>
                    <figure>
                        <button className="filterBtn"><FaFilter size={15}></FaFilter>{t('filter')}</button>
                    </figure>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {props.types && props.types.map(type => (
                        <Dropdown.Item key={type.value} onClick={() => props.updateList(type.value)}>{type.label}</Dropdown.Item>
                    ))}

                </Dropdown.Menu>
            </Dropdown>
        </>
    )

}

export default withTranslation()(FilerButton)
