import React from "react";
import { Spinner } from 'react-bootstrap'
import { Provider } from "react-redux";
import { withTranslation } from "react-i18next";
import Routes from "./routes/index";
import { store } from "./helpers";
import i18n from "./i18n";

class App extends React.Component {
  componentDidMount() {
    // Check for a stored language preference in localStorage
    const userLanguage = localStorage.getItem("userLanguage") || "en";
    i18n.changeLanguage(userLanguage);
  }

  render() {
    // const { i18n, t } = this.props;
    // // Show a loader until i18n is fully initialized
    // if (!i18n.isInitialized) {
    //   return <div>"Loading..."</div>;
    // }

    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}

export default withTranslation()(App);
