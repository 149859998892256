import React from "react";
import { Form } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { withTranslation } from "react-i18next";

class SearchBillingBox extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            allList: false,
            searchList: false
        }
    }

    async SearchItem(event) {
        let item = event.target.value
        if (item.trim().length == 0) {
            this.setState({ searchValue: '' }, () => this.props.searchTable(this.state.searchValue))
        } else {
            this.setState({ searchValue: event.target.value, searchList: true }, () => this.props.searchTable(this.state.searchValue));
        }
    }

    // async componentDidUpdate(nextProps) {
    //     console.log("search component update", this.state.searchValue)
    //     if (this.state.searchValue !== nextProps.searchValue && this.state.searchValue != '') {
    //         if(this.state.allList){
    //             let userDetails = await apiService.userPractList(this.props.email, this.props.limit, this.props.skip*this.props.limit)
    //             this.props.updateUsersList(userDetails, this.state.searchValue)
    //             this.setState({allList : false })
    //         }
    //         if(this.state.searchList && this.state.searchValue !== ""){
    //             let searchDetails = await apiService.userPractSearch(this.props.email,this.state.searchValue, this.props.limit, this.props.skip*this.props.limit)
    //             this.props.updateUsersList (searchDetails, this.state.searchValue)
    //             this.setState({searchList : false })
    //         }
    //         this.props.searchTable(this.state.searchValue);
    //     }
    // }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="searchBox-wrp">
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="mb-0">
                            <Form.Control placeholder={t('placeholder.search')} onInput={this.SearchItem.bind(this)} />
                            <Form.Control.Feedback>
                                <BiSearch
                                    size={20}
                                    style={{ margin_top: '10px' }}
                                />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </div>
            </>
        )
    }
}

export default withTranslation()(SearchBillingBox)
