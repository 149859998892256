import i18next from 'i18next';

const epiLimits = {
    "pro_inflammation_score": 1500,
    "anti_inflammation_score": 1200,
    "inflammation_score": 500,
    "default": 500
};

export function getScoreAntiPro(ScoreData) {
    // return epiLimits[ScoreData.key] ? `${ScoreData.title} (Out of ${epiLimits[ScoreData.key]})` : `${ScoreData.title} (Out of ${epiLimits['default']})`;
    return epiLimits[ScoreData.key] ?
        i18next.t('graph.inflammation_title', { title: ScoreData.title, score: epiLimits[ScoreData.key] }) :
        i18next.t('graph.inflammation_title', { title: ScoreData.title, score: epiLimits['default'] });

}
