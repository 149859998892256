import React from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdChevronRight,
    MdKeyboardArrowDown,
    MdAddBox,
    MdIndeterminateCheckBox,
    MdFolder,
    MdFolderOpen,
    MdInsertDriveFile
} from "react-icons/md";
import { connect } from "react-redux";
import { alertActions } from "../../actions";
import { getPartnerGraphColour } from "../../helpers/partner";

let graph_color = '#09A79E'
// let light_color = '#09a79e69'

class MultiSelect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: [],
            expanded: [],
            list: [],
            output: [],
            access: this.props.access
        }
    }

    componentDidMount() {
        let partner_id = this.props.user?.partner_id
        graph_color = getPartnerGraphColour(partner_id)

        this.setAccess()
    }

    componentDidUpdate(nextProps) {
        if (nextProps.access !== this.props.access) {
            this.setState({ access: this.props.access }, () => {
                this.setAccess()
            });
        }
    }

    setAccess() {
        let list = [], checked = [], output = {}
        this.state.access.map((items, ind) => {
            list.push(items)
            let selected = {}
            if (this.props.disabled) {
                // list[ind]["disabled"] = true
            }
            if (items.children && items.children.length > 0) {
                items.children.map((item, index) => {
                    selected[item.value] = item.condition
                    if (this.props.disabled) {
                        list[ind].children[index]["disabled"] = true
                    }
                    if (item.condition == true) {
                        checked.push(item.value)
                    }
                })
            } else {
                delete items.children
                // items.children = []
                selected = items.condition
                if (items.condition == true) {
                    checked.push(items.value)
                }
            }
            output[items.value] = selected
        })
        this.setState({ list, checked, output })
    }
    onChecked(check) {
        let selected = {}, output = this.state.output, list = this.state.list

        list.map((items, index) => {
            if (items.children && items.children.length > 0) {
                selected = output[items.value]
                items.children.map((item) => {
                    if (check.indexOf(item.value) >= 0) {
                        selected[item.value] = true
                        item.condition = true
                    } else {
                        selected[item.value] = false
                        item.condition = false
                    }
                })
            } else {
                selected = false

                if (check.indexOf(items.value) >= 0) {
                    selected = true;
                }
            }
            output[items.value] = selected
        })

        this.setState({ checked: check, list, output })
        this.props.handleAccessChanges(this.state.output)
    }

    render() {
        const { disabled } = this.props
        const { list } = this.state
        let size = 25
        let color = graph_color
        const icons = {
            check: <MdCheckBox className="rct-icon rct-icon-check" size={size} color={color} />,
            uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" size={size} color={color} />,
            halfCheck: (
                <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" size={size} color={color} />
            ),
            expandClose: (
                <MdChevronRight className="rct-icon rct-icon-expand-close" size={size} color={color} />
            ),
            expandOpen: (
                <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" size={size} color={color} />
            ),
            expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" size={size} color={color} />,
            collapseAll: (
                <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" size={size} color={color} />
            ),
            parentClose: <MdFolder className="rct-icon rct-icon-parent-close" size={size} color={color} />,
            parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" size={size} color={color} />,
            leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" size={size} color={color} />
        };
        return (
            <>
                <CheckboxTree
                    nodes={list}
                    checked={this.state.checked}
                    expanded={this.state.expanded}
                    onCheck={checked => this.onChecked(checked)}
                    onExpand={expanded => this.setState({ expanded })}
                    icons={icons}
                    disabled={disabled}
                />
            </>
        )
    }


}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}

const actionCreators = {
    success: alertActions.success
}

export default connect(mapState, actionCreators)(MultiSelect)

